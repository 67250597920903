export default {
  blogerName: 'MANIK',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/MaNikSTREAM',
    },
    {
      name: 'telegram',
      url: 'https://t.me/maniktopchat',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/maniktop1',
    },
    {
      name: 'vk',
      url: 'https://vk.com/maxsgameworld',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@MaNikCASINO',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@MaNikSakhalin',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@maniktop',
    }
  ],
  projects: [
    {
      name: 'gizbo',
      url: 'https://gzb-blrs10.com/c93b4e60a',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225%</strong><div>На первый депозит</div>',
    },
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c45544ab7',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c5eec9341',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c74896384',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/cfc8fcac7',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c6992cab7',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c37aae420',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c3d18dd0b',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c97e3b8da',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cd04ef3fc',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c0d48b207',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c5fb8a769',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c17862c97',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>MANIK</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'MANIK',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
